@font-face {
  font-family: "icomoon";
  src: url("../../fonts/icomoon/icomoon.eot?srf3rx");
  src: url("../../fonts/icomoon/icomoon.eot?srf3rx#iefix") format("embedded-opentype"), url("../../fonts/icomoon/icomoon.ttf?srf3rx") format("truetype"), url("../../fonts/icomoon/icomoon.woff?srf3rx") format("woff"), url("../../fonts/icomoon/icomoon.svg?srf3rx#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
.white-popup {
  position: relative;
  background: white;
  max-width: 680px;
  padding: 20px 10px;
  width: auto;
  margin: 60px auto;
}

.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.4s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

/* =======================================================
*
*   Template Style
*
* ======================================================= */
body {
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.8;
  color: #777;
  background: #fff;
}

#container-video {
  text-align: center;
  padding-top: 40px;
}
#container-video iframe {
  width: 280px;
  height: 160px;
  margin: 20px auto;
}
@media screen and (min-width: 768px) {
  #container-video iframe {
    width: 500px;
    height: 280px;
  }
}
@media screen and (min-width: 992px) {
  #container-video iframe {
    width: 640px;
    height: 360px;
  }
}

#page {
  position: relative;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.offcanvas #page:after {
  -moz-transition: all 2s ease;
  -o-transition: all 2s ease;
  -webkit-transition: all 2s ease;
  -ms-transition: all 2s ease;
  transition: all 2s ease;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.7);
  content: "";
}

a {
  color: #9D8336;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
a:hover, a:active, a:focus {
  color: #9D8336;
  outline: none;
  text-decoration: none;
}

p {
  margin-bottom: 30px;
}

h1, h2, h3, h4, h5, h6, figure {
  color: #000;
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  margin: 0 0 30px 0;
}

::-webkit-selection {
  color: #fff;
  background: #9D8336;
}

::-moz-selection {
  color: #fff;
  background: #9D8336;
}

::selection {
  color: #fff;
  background: #9D8336;
}

img.img-card {
  max-width: 75%;
  margin-left: 50%;
  transform: translateX(-50%);
}
img.img-card.card-btn-gallery {
  padding: 10px;
  max-width: 90%;
}

.btn-gallery {
  position: relative;
  display: inline-block;
}

.no-touch .btn-gallery:hover:after {
  content: "Click here for photos";
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  border-radius: 5px;
}

.subtle-text {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.gtco-nav {
  position: fixed;
  top: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  z-index: 1001;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media screen and (max-width: 768px) {
  .gtco-nav {
    padding: 0;
  }
}
.gtco-nav #gtco-logo {
  font-size: 30px;
  float: left;
  line-height: 1;
  margin: 15px 0;
  padding: 0;
  font-weight: bold;
}
.gtco-nav #gtco-logo em {
  color: #fff;
  font-weight: 100;
  font-style: normal;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.gtco-nav #gtco-logo a {
  padding: 0px 10px;
  color: #fff;
  font-weight: 700;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.gtco-nav #gtco-logo a img {
  height: 65px;
  padding: 5px;
  border-radius: 50%;
  background-color: white;
  opacity: 0.9;
}
.gtco-nav #gtco-logo .header-phone {
  display: inline-block;
  font-size: 14px;
}
@media screen and (max-width: 768px) {
  .gtco-nav .menu-1, .gtco-nav .menu-2 {
    display: none;
  }
}
.gtco-nav ul {
  padding: 0;
  margin: 1px 0 0 0;
}
.gtco-nav ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline;
}
.gtco-nav ul li a {
  display: inline-block;
  font-size: 12px;
  padding: 10px;
  margin: 2px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50vh;
  font-weight: 400 !important;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.gtco-nav ul li a:hover, .gtco-nav ul li a:focus, .gtco-nav ul li a:active {
  color: #fff;
}
.gtco-nav ul li.has-dropdown {
  position: relative;
}
.gtco-nav ul li.has-dropdown .dropdown {
  width: 130px;
  -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.15);
  z-index: 1002;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 40px;
  left: 0;
  text-align: left;
  background: #fff;
  padding: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  -moz-transition: all 0s ease;
  -o-transition: all 0s ease;
  -webkit-transition: all 0s ease;
  -ms-transition: all 0s ease;
  transition: all 0s ease;
}
.gtco-nav ul li.has-dropdown .dropdown:before {
  bottom: 100%;
  left: 40px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #fff;
  border-width: 8px;
  margin-left: -8px;
}
.gtco-nav ul li.has-dropdown .dropdown li {
  display: block;
  margin-bottom: 7px;
}
.gtco-nav ul li.has-dropdown .dropdown li:last-child {
  margin-bottom: 0;
}
.gtco-nav ul li.has-dropdown .dropdown li a {
  padding: 2px 0;
  display: block;
  color: #999999;
  line-height: 1.2;
  text-transform: none;
  font-size: 15px;
}
.gtco-nav ul li.has-dropdown .dropdown li a:hover {
  color: #000;
}
.gtco-nav ul li.has-dropdown .dropdown li.active > a {
  color: #000 !important;
}
.gtco-nav ul li.has-dropdown:hover a, .gtco-nav ul li.has-dropdown:focus a {
  color: #fff;
}
.gtco-nav ul li.btn-cta a {
  color: #fff;
}
.gtco-nav ul li.btn-cta a span {
  border: none !important;
  background: rgba(255, 255, 255, 0.2);
  padding: 4px 20px;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
}
.gtco-nav ul li.btn-cta a:hover span {
  background: #b0933d;
  color: #fff;
}
.gtco-nav ul li.active > a {
  color: #fff !important;
}
.gtco-nav.scrolled {
  background: #fff;
  webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
}
.gtco-nav.scrolled ul {
  padding: 0;
  margin: 1px 0 0 0;
}
.gtco-nav.scrolled ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline;
}
.gtco-nav.scrolled ul li a {
  color: rgba(0, 0, 0, 0.5);
  background-color: transparent;
}
.gtco-nav.scrolled ul li a:hover {
  color: black;
}
.gtco-nav.scrolled ul li.active > a {
  color: #9D8336 !important;
}
.gtco-nav.scrolled ul li.btn-cta a {
  color: #fff;
}
.gtco-nav.scrolled ul li.btn-cta a span {
  border: none !important;
  background: #9D8336;
  padding: 4px 20px;
  color: #fff;
}
.gtco-nav.scrolled #gtco-logo em, .gtco-nav.scrolled #gtco-logo a {
  margin: 0;
  font-size: 16px;
  color: #000;
}
.gtco-nav.scrolled #gtco-logo .header-phone a {
  font-size: 14px;
}

.card {
  margin: 10px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.card:hover {
  -webkit-box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
}

#gtco-hero .container-mail-info a {
  color: white;
  text-decoration: underline;
}
@media screen and (max-width: 768px) {
  #gtco-hero .container-mail-info {
    padding-top: 65px;
  }
}
#gtco-hero .gtco-video-link a {
  color: rgba(255, 255, 255, 0.5);
}
#gtco-hero .gtco-video-link a i {
  font-size: 78px;
  top: 2px;
  position: relative;
  color: #fff;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
#gtco-hero .gtco-video-link a i::before {
  font-size: 60px;
}
#gtco-hero .gtco-video-link a:hover {
  color: white;
}
#gtco-hero .gtco-video-link a:hover i {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
@media screen and (max-width: 480px) {
  #gtco-hero .text-left {
    text-align: center !important;
  }
}
@media screen and (max-width: 480px) {
  #gtco-hero .btn {
    display: block;
    width: 100%;
  }
}

#gtco-header,
#gtco-counter,
.gtco-bg {
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  position: relative;
}

.gtco-bg {
  background-position: center center;
  width: 100%;
  float: left;
  position: relative;
}

.gtco-video {
  height: 450px;
  overflow: hidden;
  margin-bottom: 30px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
}
.gtco-video.gtco-video-sm {
  height: 250px;
}
.gtco-video a {
  z-index: 1001;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -45px;
  margin-left: -45px;
  width: 90px;
  height: 90px;
  display: table;
  text-align: center;
  background: #fff;
  -webkit-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.gtco-video a i {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-size: 40px;
}
.gtco-video .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.gtco-video:hover .overlay {
  background: rgba(0, 0, 0, 0.7);
}
.gtco-video:hover a {
  position: relative;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.gtco-cover {
  height: 900px;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
}
.gtco-cover a {
  color: #9D8336;
}
.gtco-cover a:hover {
  color: white;
}
.gtco-cover .header-bg {
  width: 100%;
  height: 900px;
  z-index: -1;
  position: fixed;
  background-size: cover;
  background-image: url(/../images/img_bg_header.jpg);
  background-repeat: no-repeat;
}
@media screen and (max-width: 768px) {
  .gtco-cover .header-bg {
    height: 600px;
  }
}
.gtco-cover .overlay {
  z-index: 1;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(35, 35, 35, 0.5);
}
.gtco-cover > .container {
  position: relative;
  z-index: 10;
}
@media screen and (max-width: 768px) {
  .gtco-cover {
    height: 600px;
  }
}
.gtco-cover .display-t,
.gtco-cover .display-tc {
  height: 900px;
  display: table;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .gtco-cover .display-t,
.gtco-cover .display-tc {
    height: 600px;
  }
}
.gtco-cover.gtco-cover-sm {
  height: 600px;
}
@media screen and (max-width: 768px) {
  .gtco-cover.gtco-cover-sm {
    height: 400px;
  }
}
.gtco-cover.gtco-cover-sm .display-t,
.gtco-cover.gtco-cover-sm .display-tc {
  height: 600px;
  display: table;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .gtco-cover.gtco-cover-sm .display-t,
.gtco-cover.gtco-cover-sm .display-tc {
    height: 400px;
  }
}
.gtco-cover.gtco-cover-xs {
  height: 500px;
}
@media screen and (max-width: 768px) {
  .gtco-cover.gtco-cover-xs {
    height: inherit !important;
    padding: 3em 0;
  }
}
.gtco-cover.gtco-cover-xs .display-t,
.gtco-cover.gtco-cover-xs .display-tc {
  height: 500px;
  display: table;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .gtco-cover.gtco-cover-xs .display-t,
.gtco-cover.gtco-cover-xs .display-tc {
    padding: 3em 0;
    height: inherit !important;
  }
}
.gtco-cover.gtco-cover-xxs {
  padding: 3em 0;
}
.gtco-cover .btn-primary {
  color: #fff !important;
}

.gtco-section-item {
  padding: 4em 0;
  clear: both;
  background-color: white;
}

.gtco-section-item .img-shadow {
  position: relative;
  background: #9D8336;
  width: 100%;
  left: 10px;
  max-width: 370px;
  max-height: 370px;
}
.gtco-section-item .img-shadow img {
  position: relative;
  top: -20px;
  left: -20px;
  max-height: 370px;
}
.gtco-section-item .img-shadow div {
  background-color: white;
}
@media screen and (max-width: 992px) {
  .gtco-section-item .img-shadow {
    margin-left: 50%;
    transform: translateX(-50%);
  }
}
.gtco-section-item .heading {
  position: relative;
}
.gtco-section-item .heading .subtle-text {
  position: absolute;
  bottom: -1.6em;
  left: 0;
  text-transform: uppercase;
  font-size: 208px;
  color: #F8F8F8;
  font-weight: 900;
  z-index: -1;
}

.gtco-section-item .heading {
  position: relative;
}
.gtco-section-item .heading .subtle-text {
  position: absolute;
  bottom: -1.6em;
  left: 0;
  text-transform: uppercase;
  font-size: 208px;
  color: #F8F8F8;
  font-weight: 900;
  z-index: -1;
  line-height: 1;
}
.gtco-section-item .heading .subtle-text span {
  display: block;
}
.gtco-section-item .gtco-section-item-sub {
  margin-bottom: 30px;
  margin-bottom: 30px;
}
.gtco-section-item .gtco-section-item-sub .gtco-icon,
.gtco-section-item .gtco-section-item-sub .gtco-sub {
  vertical-align: top;
}
.gtco-section-item .gtco-section-item-sub .gtco-icon {
  width: 100px;
}
@media screen and (max-width: 768px) {
  .gtco-section-item .gtco-section-item-sub .gtco-icon {
    width: 70px;
  }
}
.gtco-section-item .gtco-section-item-sub .gtco-sub h2, .gtco-section-item .gtco-section-item-sub .gtco-sub h3 {
  margin-bottom: 15px;
  font-size: 24px;
  text-align: center;
}
.gtco-section-item .gtco-section-item-sub .gtco-sub p {
  text-align: justify;
}
.gtco-section-item .gtco-section-item-sub .gtco-icon,
.gtco-section-item .gtco-section-item-sub .gtco-copy {
  vertical-align: top;
}
.gtco-section-item .gtco-section-item-sub .gtco-icon {
  width: 100px;
}
@media screen and (max-width: 768px) {
  .gtco-section-item .gtco-section-item-sub .gtco-icon {
    width: 70px;
  }
}
.gtco-section-item .gtco-section-item-sub .gtco-copy h2, .gtco-section-item .gtco-section-item-sub .gtco-copy h3 {
  margin-bottom: 15px;
  font-size: 24px;
  text-align: center;
}
.gtco-section-item .gtco-section-item-sub .gtco-copy p {
  text-align: justify;
}
.gtco-section-item .product-image-container {
  text-align: center;
  margin-bottom: 15px;
}
.gtco-section-item .product-image-container a img {
  padding: 10px;
  max-width: 90%;
  max-height: 350px;
  margin-left: 50%;
  transform: translateX(-50%);
}
.gtco-section-item .gallery-image-links {
  overflow: hidden;
  display: none;
  visibility: hidden;
}
.gtco-section-item .img-alopecia {
  max-width: 80%;
  margin: 30px auto;
}
.gtco-section-item .section-text {
  text-align: justify;
}

.heading {
  text-align: center;
  margin-bottom: 70px;
}
.heading h1 {
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .heading h1 {
    font-size: 26px;
  }
}
.heading .sub {
  position: relative;
  padding-bottom: 40px;
  font-size: 18px;
  color: #757575;
}
.heading .sub:after {
  bottom: 0;
  left: 50%;
  margin-left: -36px;
  content: "";
  position: absolute;
  width: 72px;
  height: 2px;
  background: #9D8336;
}

.heading-colored {
  color: #9D8336;
}

.container-centered {
  text-align: center;
}

.read-more i {
  position: relative;
  top: 2px;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.read-more:hover i {
  padding-left: 10px;
}

.read-more-dropdown {
  display: none;
}
.read-more-dropdown #reference {
  margin: 40px auto;
  text-align: center;
}

.gtco-social-icons {
  margin: 0;
  padding: 0;
}
.gtco-social-icons li {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.gtco-social-icons li a {
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  color: #9D8336;
  padding-left: 10px;
  padding-right: 10px;
}
.gtco-social-icons li a i {
  font-size: 20px;
}

.gtco-contact-info {
  margin-bottom: 30px;
  float: left;
  width: 100%;
  position: relative;
}
.gtco-contact-info ul {
  padding: 0;
  margin: 0;
  clear: both;
}
.gtco-contact-info ul li {
  padding: 0 0 0 50px;
  margin: 0 0 30px 0;
  list-style: none;
  position: relative;
}
.gtco-contact-info ul li:before {
  color: #9d8336;
  position: absolute;
  left: 0;
  top: 0.05em;
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.gtco-contact-info ul li.address:before {
  font-size: 30px;
  content: "\e9d1";
}
.gtco-contact-info ul li.phone:before {
  font-size: 23px;
  content: "\e9f4";
}
.gtco-contact-info ul li.email:before {
  font-size: 23px;
  content: "\e9da";
}
.gtco-contact-info ul li.email img {
  position: absolute;
  width: 20px;
  top: -5px;
}
.gtco-contact-info ul li.email img#img-flag-en {
  left: 15px;
}
.gtco-contact-info ul li.email img#img-flag-it {
  left: 28px;
}
.gtco-contact-info ul li.url:before {
  font-size: 23px;
  content: "\e9af";
}
@media screen and (max-width: 768px) {
  .gtco-contact-info {
    width: 200px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
}

.form-main label {
  font-weight: normal !important;
}
.form-main textarea {
  resize: vertical;
  min-height: 200px;
}
.form-main .btn-submit input {
  width: 100%;
}

#gtco-header .display-tc,
.gtco-cover .display-tc {
  display: table-cell !important;
  vertical-align: middle;
}
#gtco-header .display-tc h1, #gtco-header .display-tc h2,
.gtco-cover .display-tc h1,
.gtco-cover .display-tc h2 {
  margin: 0;
  padding: 0;
  color: white;
}
#gtco-header .display-tc h1,
.gtco-cover .display-tc h1 {
  margin-bottom: 30px;
  font-size: 59px;
  line-height: 1.5;
  font-weight: 100;
}
@media screen and (max-width: 768px) {
  #gtco-header .display-tc h1,
.gtco-cover .display-tc h1 {
    font-size: 34px;
    line-height: 1.2;
    margin-bottom: 10px;
  }
}
#gtco-header .display-tc h2,
.gtco-cover .display-tc h2 {
  font-size: 22px;
  line-height: 1.5;
  margin-bottom: 30px;
}

#gtco-footer {
  padding: 30px;
  background: #fafafa;
}
#gtco-footer .gtco-footer-links {
  padding: 0;
  margin: 0 0 20px 0;
  float: left;
  width: 100%;
}
#gtco-footer .gtco-footer-links li {
  padding: 0;
  margin: 0 0 15px 0;
  list-style: none;
  line-height: 1;
}
#gtco-footer .gtco-footer-links li a {
  text-decoration: none;
}
#gtco-footer .gtco-footer-links li a:hover {
  text-decoration: underline;
}
#gtco-footer .gtco-widget {
  margin-bottom: 30px;
}
#gtco-footer .gtco-widget h3 {
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
#gtco-footer .gtco-widget .gtco-quick-contact {
  padding: 0;
  margin: 0;
}
#gtco-footer .gtco-widget .gtco-quick-contact li {
  padding: 0;
  margin: 0 0 10px 0;
  list-style: none;
}
#gtco-footer .gtco-widget .gtco-quick-contact li i {
  width: 30px;
  float: left;
  font-size: 18px;
  position: relative;
  margin-top: 4px;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
@media screen and (max-width: 768px) {
  #gtco-footer .copyright .pull-left,
#gtco-footer .copyright .pull-right {
    float: none !important;
    text-align: center;
  }
}
#gtco-footer .copyright .block {
  display: block;
}

#gtco-offcanvas {
  position: absolute;
  position: fixed;
  z-index: 1901;
  width: 270px;
  background: black;
  top: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 45px 40px 40px 40px;
  overflow-y: auto;
  display: none;
  -moz-transform: translateX(270px);
  -webkit-transform: translateX(270px);
  -ms-transform: translateX(270px);
  -o-transform: translateX(270px);
  transform: translateX(270px);
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
@media screen and (max-width: 768px) {
  #gtco-offcanvas {
    display: block;
  }
}
.offcanvas #gtco-offcanvas {
  -moz-transform: translateX(0px);
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transform: translateX(0px);
}
#gtco-offcanvas a {
  color: rgba(255, 255, 255, 0.5);
}
#gtco-offcanvas a:hover {
  color: rgba(255, 255, 255, 0.8);
}
#gtco-offcanvas ul {
  padding: 0;
  margin: 0;
}
#gtco-offcanvas ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  margin: 15px auto;
}
#gtco-offcanvas ul li > ul {
  padding-left: 20px;
  display: none;
}
#gtco-offcanvas ul li.btn-cta {
  border-style: solid;
  border-width: 2px;
  border-radius: 16px;
}
#gtco-offcanvas ul li.offcanvas-has-dropdown > a {
  display: block;
  position: relative;
}
#gtco-offcanvas ul li.offcanvas-has-dropdown > a:after {
  position: absolute;
  right: 0px;
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e921";
  font-size: 20px;
  color: rgba(255, 255, 255, 0.2);
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
#gtco-offcanvas ul li.offcanvas-has-dropdown.active a:after {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.uppercase {
  font-size: 14px;
  color: #000;
  margin-bottom: 10px;
  font-weight: 700;
  text-transform: uppercase;
}

.gototop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.gototop.active {
  opacity: 1;
  visibility: visible;
}
.gototop a {
  width: 50px;
  height: 50px;
  display: table;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
.gototop a i {
  height: 50px;
  display: table-cell;
  vertical-align: middle;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.gototop a:hover, .gototop a:active, .gototop a:focus {
  text-decoration: none;
  outline: none;
}
.gototop:hover i {
  transform: scale(1.3);
}

.gtco-nav-toggle {
  width: 25px;
  height: 25px;
  cursor: pointer;
  text-decoration: none;
}
.gtco-nav-toggle.active i::before, .gtco-nav-toggle.active i::after {
  background: #444;
}
.gtco-nav-toggle:hover, .gtco-nav-toggle:focus, .gtco-nav-toggle:active {
  outline: none;
  border-bottom: none !important;
}
.gtco-nav-toggle i {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 2px;
  color: #fff;
  font: bold 14px/0.4 Helvetica;
  text-transform: uppercase;
  text-indent: -55px;
  background: #fff;
  transition: all 0.2s ease-out;
}
.gtco-nav-toggle i::before, .gtco-nav-toggle i::after {
  content: "";
  width: 25px;
  height: 2px;
  background: #fff;
  position: absolute;
  left: 0;
  transition: all 0.2s ease-out;
}
.gtco-nav-toggle.gtco-nav-white > i {
  color: #fff;
  background: #fff;
}
.gtco-nav-toggle.gtco-nav-white > i::before, .gtco-nav-toggle.gtco-nav-white > i::after {
  background: #fff;
}
.gtco-nav-toggle.scrolled:not(.active) i {
  background-color: #252525;
}
.gtco-nav-toggle.scrolled:not(.active) i::before, .gtco-nav-toggle.scrolled:not(.active) i::after {
  background-color: #252525;
}

.gtco-nav-toggle i::before {
  top: -8px;
}

.gtco-nav-toggle i::after {
  bottom: -8px;
}

.gtco-nav-toggle.active i {
  background: transparent;
}

.gtco-nav-toggle.active i::before {
  top: 0;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.gtco-nav-toggle.active i::after {
  bottom: 0;
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}

.gtco-nav-toggle {
  position: fixed;
  right: 0px;
  top: 10px;
  z-index: 21;
  padding: 3px 0 0 0;
  display: block;
  margin: 0 auto;
  display: none;
  height: 44px;
  width: 44px;
  z-index: 2001;
  border-bottom: none !important;
}
@media screen and (max-width: 768px) {
  .gtco-nav-toggle {
    display: block;
  }
}

.btn {
  margin-right: 4px;
  margin-bottom: 4px;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding: 8px 30px;
}
.btn.btn-md {
  padding: 8px 20px !important;
}
.btn.btn-lg {
  padding: 18px 36px !important;
}
.btn:hover, .btn:active, .btn:focus {
  box-shadow: none !important;
  outline: none !important;
}

.btn-primary {
  background: #9D8336;
  color: #fff;
  border: 2px solid #9D8336 !important;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  background: #b0933d !important;
  border-color: #b0933d !important;
}
.btn-primary.btn-outline {
  background: transparent;
  color: #9D8336;
  border: 2px solid #9D8336;
}
.btn-primary.btn-outline:hover, .btn-primary.btn-outline:focus, .btn-primary.btn-outline:active {
  background: #9D8336;
  color: #fff;
}

.btn-success {
  background: #5cb85c;
  color: #fff;
  border: 2px solid #5cb85c;
}
.btn-success:hover, .btn-success:focus, .btn-success:active {
  background: #4cae4c !important;
  border-color: #4cae4c !important;
}
.btn-success.btn-outline {
  background: transparent;
  color: #5cb85c;
  border: 2px solid #5cb85c;
}
.btn-success.btn-outline:hover, .btn-success.btn-outline:focus, .btn-success.btn-outline:active {
  background: #5cb85c;
  color: #fff;
}

.btn-info {
  background: #5bc0de;
  color: #fff;
  border: 2px solid #5bc0de;
}
.btn-info:hover, .btn-info:focus, .btn-info:active {
  background: #46b8da !important;
  border-color: #46b8da !important;
}
.btn-info.btn-outline {
  background: transparent;
  color: #5bc0de;
  border: 2px solid #5bc0de;
}
.btn-info.btn-outline:hover, .btn-info.btn-outline:focus, .btn-info.btn-outline:active {
  background: #5bc0de;
  color: #fff;
}

.btn-warning {
  background: #f0ad4e;
  color: #fff;
  border: 2px solid #f0ad4e;
}
.btn-warning:hover, .btn-warning:focus, .btn-warning:active {
  background: #eea236 !important;
  border-color: #eea236 !important;
}
.btn-warning.btn-outline {
  background: transparent;
  color: #f0ad4e;
  border: 2px solid #f0ad4e;
}
.btn-warning.btn-outline:hover, .btn-warning.btn-outline:focus, .btn-warning.btn-outline:active {
  background: #f0ad4e;
  color: #fff;
}

.btn-danger {
  background: #d9534f;
  color: #fff;
  border: 2px solid #d9534f;
}
.btn-danger:hover, .btn-danger:focus, .btn-danger:active {
  background: #d43f3a !important;
  border-color: #d43f3a !important;
}
.btn-danger.btn-outline {
  background: transparent;
  color: #d9534f;
  border: 2px solid #d9534f;
}
.btn-danger.btn-outline:hover, .btn-danger.btn-outline:focus, .btn-danger.btn-outline:active {
  background: #d9534f;
  color: #fff;
}

.btn-white {
  background: #fff;
  color: #000;
  border: 2px solid #fff;
}
.btn-white:hover, .btn-white:focus, .btn-white:active {
  color: #000;
  background: #f2f2f2 !important;
  border-color: #f2f2f2 !important;
}
.btn-white.btn-outline {
  color: #fff;
  border: 2px solid #fff;
}
.btn-white.btn-outline:hover, .btn-white.btn-outline:focus, .btn-white.btn-outline:active {
  background: #fff;
  color: #000;
  border: 2px solid #fff;
}

.btn-outline {
  background: none;
  border: 2px solid gray;
  font-size: 16px;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn-outline:hover, .btn-outline:focus, .btn-outline:active {
  box-shadow: none;
}

.btn.with-arrow {
  position: relative;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn.with-arrow i {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -8px;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.btn.with-arrow:hover {
  padding-right: 50px;
}
.btn.with-arrow:hover i {
  color: #fff;
  right: 18px;
  visibility: visible;
  opacity: 1;
}

.row-pb-md {
  padding-bottom: 4em !important;
}

.row-pb-sm {
  padding-bottom: 2em !important;
}

.gtco-loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url(../../images/loader.gif) center no-repeat #fff;
}

.js .animate-box {
  opacity: 0;
}